import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthorization, IAuthorizationState, SignInFormRequest } from "./authorization.types";
import { authorizationService } from "./authorization.service";
import { AuthorizationPolicy } from "../../../viewModels/application/authorizationPolicy";
import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { ActionStatus, Result } from "../../config";
import { jwtDecode } from "jwt-decode";
import { GetUserSessionPersmissionsResponse } from "../../../viewModels/authentication/getUserSessionPersmissionsResponse";
import { authenticationService } from "../authentication";
import { appLogService } from "../appLog";
import { SignInWithPasswordResponse } from "../../../viewModels/authentication/signInWithPasswordResponse";
import { localStorageService } from "../../../common/services/localStorage.service";
import { lms3Service } from "../lms3";
import { SignInWithAzureRequest } from "../../../viewModels/authentication/signInWithAzureRequest";
import { SignInWithOktaRequest } from "../../../viewModels/authentication/signInWithOktaRequest";
import { SignInWithAdfsRequest } from "../../../viewModels/authentication/signInWithAdfsRequest";
import { AssumeIdentityRequest } from "../../../viewModels/authentication/assumeIdentityRequest";

const pageStrings = new PageStrings();

const initialState: IAuthorizationState = {
    token: null,
    policies: {},
    status: ActionStatus.idle,
    assumeIdentityPropagationStatus: ActionStatus.idle,
    assumeIdentityStatus: ActionStatus.idle,
    signOutStatus: ActionStatus.idle,
    signOutPropagationStatus: ActionStatus.idle,
    signInStatus: ActionStatus.idle,
    signInErrorMessage: '',
    signInPropagationStatus: ActionStatus.idle,
    error: '',
    claims: {},
    userPermissions: {},
    securityProfileLevels: [],
    maxSecurityProfileLevel: 0,
    userVisibility: 0,
    parentUserSessionId: '',
    revertIdentityErrorMessage: '',
    revertIdentityStatus: ActionStatus.idle,
    revertIdentityPropagationStatus: ActionStatus.idle,
    isAuthenticated: false,
    isAssumingIdentity: false,
    isInitialLoad: true,
    userDisplayName: '',
    userIdentifier: ''
}

export const getPolicies = createAsyncThunk('application/policies', async() =>{
    return await authorizationService.getPolicies();
});

export const getUserPermissions = createAsyncThunk('authorization/usersession/permissions', async() =>{
    return await authorizationService.getUserPermissions();
});

export const signInWithForm = createAsyncThunk('authentication/signin/form', async(request: SignInFormRequest) => {
    return await authenticationService.signIn({
        password: request.password,
        username: request.username,
        timeZoneInfoID: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
});

export const assumeIdentity = createAsyncThunk('authentication/identity/assume', async(request: AssumeIdentityRequest) => {
    return await authenticationService.assumeIdentity(request);
})

export const revertIdentity = createAsyncThunk('authentication/identity/revert', async() => {
    return await authenticationService.revertIdentity();
});

export const signInWithAdfs = createAsyncThunk('authentication/signin/adfs', async(request: SignInWithAdfsRequest) => {
    return await authenticationService.adfsSignIn(request);
});

export const signInWithAzure = createAsyncThunk('authentication/signin/azure', async(request: SignInWithAzureRequest) => {
    return await authenticationService.azureSignIn(request);
});

export const signInWithOkta = createAsyncThunk('authentication/signin/okta', async(request: SignInWithOktaRequest) => {
    return await authenticationService.oktaSignIn(request);
});

export const signOut = createAsyncThunk('authentication/signout', async () => {
    return await authenticationService.signOut();
})

export const lms3SetLms4Cookie = createAsyncThunk('lms3/cookies/setLms4', async() => {
    return await lms3Service.setLms4Cookie();
});

export const lms3clearLms4Cookie = createAsyncThunk('lms3/cookies/clearLms4', async() => {
    return await lms3Service.clearLms4Cookie();
});

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {


        sessionExpired: (state: IAuthorizationState) => {
            state = initialState;
            localStorageService.setAuthorizationToken(state.token??'');
            localStorageService.setUserPermissions(state.userPermissions);
            localStorageService.removeAction();
        },

        setAuthorization: (state: IAuthorizationState, action: PayloadAction<IAuthorization>) => {

            if (action.payload.token){

                setAuthorizationState(state, action.payload.token, action.payload.userPermissions);
            }
        },

        policiesStatusSucceded (state: IAuthorizationState){
            state.status = ActionStatus.succeeded;
        }
    },
    extraReducers: (builder) => { 

        builder.addCase(getPolicies.pending, (state: IAuthorizationState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getPolicies.rejected, (state: IAuthorizationState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getPolicies.fulfilled, (state: IAuthorizationState, action: PayloadAction<{[id: string]: AuthorizationPolicy}>) => {
            state.status = ActionStatus.succeeded;
            state.policies = action.payload;
            state.isInitialLoad = false;
        });

        builder.addCase(getUserPermissions.pending, (state: IAuthorizationState) => {
            // state.status = ActionStatus.loading;
        });

        builder.addCase(getUserPermissions.rejected, (state: IAuthorizationState) => {
            // state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getUserPermissions.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<GetUserSessionPersmissionsResponse>>) => {
            // state.status = ActionStatus.succeeded;
            if(action.payload.value){
                state.userPermissions = action.payload.value.userSessionPermissions;
                state.maxSecurityProfileLevel = action.payload.value.maxSecurityProfileLevel;
                state.userVisibility = action.payload.value.userVisibility;
                state.securityProfileLevels = action.payload.value.securityProfileLevels;
            }
        });

        builder.addCase(assumeIdentity.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.assumeIdentityStatus = ActionStatus.loading
        });

        builder.addCase(assumeIdentity.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.assumeIdentityStatus = ActionStatus.failed;
            appLogService.fatal('authorization.slice.ts', 'assumeIdentity', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(assumeIdentity.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {

            if (action.payload.isSuccess){

                state.assumeIdentityStatus = ActionStatus.succeeded;
                setAuthorizationState(state, action.payload.value.token, action.payload.value.permissions);

            } else {

                appLogService.fatal('authorization.slice.ts', 'assumeIdentity', 'payloadFailed', JSON.stringify(action.payload));

            }

        });


        builder.addCase(signInWithForm.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.signInStatus = ActionStatus.loading;
        });

        builder.addCase(signInWithForm.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.signInStatus = ActionStatus.failed;
            state.signInErrorMessage = pageStrings.general.error_Unexpected;
            appLogService.fatal('authorization.slice.ts', 'signInWithForm', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(signInWithForm.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {

            if (action.payload.isSuccess){

                state.signInStatus = ActionStatus.succeeded;
                setAuthorizationState(state, action.payload.value.token, action.payload.value.permissions);

            } else {

                state.signInStatus = ActionStatus.failed;
                state.signInErrorMessage = action.payload.errorMessage;
                appLogService.fatal('authorization.slice.ts', 'signInWithForm', 'payloadFailed', JSON.stringify(action.payload));

            }

        });


        builder.addCase(lms3SetLms4Cookie.pending, (state: IAuthorizationState) => {
            if (state.assumeIdentityStatus === ActionStatus.succeeded) {
                state.assumeIdentityPropagationStatus = ActionStatus.loading
            } 
            else if (state.revertIdentityStatus === ActionStatus.succeeded) {
                state.revertIdentityPropagationStatus = ActionStatus.loading
            } else {
                state.signInPropagationStatus = ActionStatus.loading;
            }
        });

        builder.addCase(lms3SetLms4Cookie.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            if (state.assumeIdentityStatus === ActionStatus.succeeded) {
                state.assumeIdentityPropagationStatus = ActionStatus.failed
            } else if (state.revertIdentityStatus === ActionStatus.succeeded) {
                state.revertIdentityPropagationStatus = ActionStatus.failed
            } else {
                state.signInPropagationStatus = ActionStatus.failed;         
                state.signInErrorMessage = pageStrings.general.error_Unexpected;
            }
            
            appLogService.fatal('authorization.slice.ts', 'lms3SetLms4Cookie', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(lms3SetLms4Cookie.fulfilled, (state: IAuthorizationState) => {
            if (state.assumeIdentityStatus === ActionStatus.succeeded) {
                state.assumeIdentityPropagationStatus = ActionStatus.succeeded
            } else if (state.revertIdentityStatus === ActionStatus.succeeded) {
                state.revertIdentityPropagationStatus = ActionStatus.succeeded
            }  else {
                state.signInPropagationStatus = ActionStatus.succeeded;
                state.signInErrorMessage = '';
            }
        });

        
        builder.addCase(lms3clearLms4Cookie.pending, (state: IAuthorizationState) => {
            state.signOutPropagationStatus = ActionStatus.loading;
        });

        builder.addCase(lms3clearLms4Cookie.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.signOutPropagationStatus = ActionStatus.failed;
            appLogService.fatal('authorization.slice.ts', 'lms3clearLms4Cookie', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(lms3clearLms4Cookie.fulfilled, (state: IAuthorizationState) => {
            state.signOutPropagationStatus = ActionStatus.succeeded;
        });
        
        
        builder.addCase(signInWithAdfs.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.signInStatus = ActionStatus.loading;
        });

        builder.addCase(signInWithAdfs.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.signInStatus = ActionStatus.failed;
            state.signInErrorMessage = pageStrings.general.error_Unexpected;
            appLogService.fatal('authorization.slice.ts', 'signInWithAdfs', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(signInWithAdfs.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {

            if (action.payload.isSuccess){

                state.signInStatus = ActionStatus.succeeded;
                setAuthorizationState(state, action.payload.value.token, action.payload.value.permissions);

            } else {

                state.signInStatus = ActionStatus.failed;
                state.signInErrorMessage = action.payload.errorMessage;
                appLogService.fatal('authorization.slice.ts', 'signInWithAdfs', 'payloadFailed', JSON.stringify(action.payload));

            }

        });

        builder.addCase(signInWithAzure.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.signInStatus = ActionStatus.loading;
        });

        builder.addCase(signInWithAzure.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.signInStatus = ActionStatus.failed;
            state.signInErrorMessage = pageStrings.general.error_Unexpected;
            appLogService.fatal('authorization.slice.ts', 'signInWithAzure', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(signInWithAzure.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {

            if (action.payload.isSuccess){

                state.signInStatus = ActionStatus.succeeded;
                setAuthorizationState(state, action.payload.value.token, action.payload.value.permissions);

            } else {

                state.signInStatus = ActionStatus.failed;
                state.signInErrorMessage = action.payload.errorMessage;
                appLogService.fatal('authorization.slice.ts', 'signInWithAzure', 'payloadFailed', JSON.stringify(action.payload));

            }

        });

        builder.addCase(signInWithOkta.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.signInStatus = ActionStatus.loading;
        });

        builder.addCase(signInWithOkta.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.signInStatus = ActionStatus.failed;
            state.signInErrorMessage = pageStrings.general.error_Unexpected;
            appLogService.fatal('authorization.slice.ts', 'signInWithOkta', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(signInWithOkta.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {

            if (action.payload.isSuccess){

                state.signInStatus = ActionStatus.succeeded;
                setAuthorizationState(state, action.payload.value.token, action.payload.value.permissions);

            } else {

                state.signInStatus = ActionStatus.failed;
                state.signInErrorMessage = action.payload.errorMessage;
                appLogService.fatal('authorization.slice.ts', 'signInWithOkta', 'payloadFailed', JSON.stringify(action.payload));

            }

        });

        
        builder.addCase(signOut.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.signOutStatus = ActionStatus.loading;
        });

        builder.addCase(signOut.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.signOutStatus = ActionStatus.failed;
            appLogService.fatal('authorization.slice.ts', 'signOut', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(signOut.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<string>>) => {

            if (action.payload.isSuccess){

                state.signOutStatus = ActionStatus.succeeded;
                clearAuthorizationState(state);

            } else {

                state.signOutStatus = ActionStatus.failed;
                appLogService.fatal('authorization.slice.ts', 'signOut', 'payloadFailed', JSON.stringify(action.payload));

            }

        });

        builder.addCase(revertIdentity.pending, (state: IAuthorizationState) => {
            resetActionStatus(state);
            state.revertIdentityStatus = ActionStatus.loading;
            state.revertIdentityErrorMessage = '';
        });

        builder.addCase(revertIdentity.rejected, (state: IAuthorizationState, action: PayloadAction<any>) => {
            state.revertIdentityErrorMessage = pageStrings.general.error_Unexpected;
            state.revertIdentityStatus = ActionStatus.failed;
            appLogService.fatal('authorization.slice.ts', 'revertIdentity', 'rejected', JSON.stringify(action.payload));
        });

        builder.addCase(revertIdentity.fulfilled, (state: IAuthorizationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {
           
            if (action.payload.isSuccess){

                state.revertIdentityStatus = ActionStatus.succeeded;

                setAuthorizationState(state, action.payload.value.token, action.payload.value.permissions);

            } else {

                state.revertIdentityErrorMessage = pageStrings.general.error_Unexpected;
                state.revertIdentityStatus = ActionStatus.failed;
                appLogService.fatal('authorization.slice.ts', 'revertIdentity', 'payloadFailed', JSON.stringify(action.payload));

            }
        });
    }
});

export const {
    sessionExpired,
    setAuthorization,
    policiesStatusSucceded,
} = authorizationSlice.actions;

export default authorizationSlice.reducer;

export function decodeToken(token: string) {
    return jwtDecode(token) as { [key: string]: string };
}

function clearAuthorizationState(state: IAuthorizationState){

    state.isAuthenticated = false;
    state.token = '';
    state.claims = {};
    state.userDisplayName = '';
    state.userIdentifier = '';
    state.userPermissions = {};

    state.isAssumingIdentity = false;
    state.parentUserSessionId = '';
    
    localStorageService.removeAuthorization();

}

function setAuthorizationState(state: IAuthorizationState, token: string, userPermissions: { [key: string]: string }){

    var claims = decodeToken(token);
                
    state.isAuthenticated = true;
    state.token = token;
    state.claims = claims;
    state.userDisplayName = claims['Intellek.Lms.UserDisplayName'];
    state.userIdentifier = claims['Intellek.Lms.UserIdentifier'];
    state.userPermissions = userPermissions;

    const parentUserSessionUid = claims['Intellek.Lms.ParentUserSessionUid']??'';

    if (parentUserSessionUid){
        state.isAssumingIdentity = true;
        state.parentUserSessionId = parentUserSessionUid;
    } else {
        state.isAssumingIdentity = false;
        state.parentUserSessionId = '';
    }

    
    localStorageService.setAuthorizationToken(state.token);
    localStorageService.setUserPermissions(state.userPermissions);

    console.log(claims['Intellek.Lms.UserSessionId']);
    console.log(claims['Intellek.Lms.LearnerId']);
}

function resetActionStatus(state: IAuthorizationState){

    state.assumeIdentityPropagationStatus = ActionStatus.idle;
    state.assumeIdentityStatus = ActionStatus.idle;
    state.revertIdentityPropagationStatus = ActionStatus.idle;
    state.revertIdentityStatus = ActionStatus.idle;
    state.signInPropagationStatus = ActionStatus.idle;
    state.signInStatus = ActionStatus.idle;
    state.signOutPropagationStatus = ActionStatus.idle;
    state.signOutStatus = ActionStatus.idle;
}
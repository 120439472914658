import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { configurationService } from "./configuration.service";
import { IConfigurationState } from "./configuration.types";

import { Configuration } from '../../../viewModels/application/configuration';
import { BasicConfiguration } from '../../../viewModels/application/basicConfiguration';
import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { ActionStatus } from "../../config";
import { VisibilityOption } from "../../../viewModels/configuration/visibilityOption";
import { Authentication } from "../../../viewModels/application/configuration/authentication";
import { AccountSelfCreation } from "../../../viewModels/application/configuration/accountSelfCreation";

const initialState: IConfigurationState = {
    error: null,
    status: ActionStatus.idle,
    authentication: {
        adfs_IsEnabled: false,
        azure_IsEnabled: false,
        okta_isEnabled: false,
        usernameAndPassword_isEnabled: false,
        azure_ClientId: "",
        azure_TenantId: "",
        azure_RedirectUri: "",
        okta_AccountName: "",
        okta_ReturnUrl: "",
        okta_AppId: "",
        okta_AuthorizationServer: "",
        adfs_ClientId: "",
        adfs_OrgUri: "",
        adfs_ReturnUrl: "",        
    },    
    branding: {
        companyLogo: "",
        companyName: "",
        keyColor_primary: "",
        keyColor_secondary: ""
    },
    pages: {
        calendar_IsDisplayed: false,
        history_IsDisplayed: false,
        library_IsDisplayed: false,
        users_IsDisplayed: false
    },
    accountSelfCreation:{
        isEnabled:false,
        formConfiguration_Department: VisibilityOption.Hidden,
        formConfiguration_Location:VisibilityOption.Hidden,
        formConfiguration_PracticeArea:VisibilityOption.Hidden,
        formConfiguration_Telephone:VisibilityOption.Hidden,
        formConfiguration_Title:VisibilityOption.Hidden,
        formConfiguration_UserProfile:VisibilityOption.Hidden,
        formConfiguration_Username:VisibilityOption.Hidden,

    }
};

const pageStrings = new PageStrings();

export const getConfiguration = createAsyncThunk("application/configuration", async () => {
    const response = await configurationService.getConfiguration();
    return response;
});

export const getConfigurationBasic = createAsyncThunk("application/configuration/basic", async () => {
    const response = await configurationService.getConfigurationBasic();
    return response;
});

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {

        resetStatus: (state: IConfigurationState) => {
            state.status = ActionStatus.idle;
        },

        authenticationSettingsChanged: (state: IConfigurationState, action: PayloadAction<Authentication>) => {
            state.authentication = action.payload;
        },
        accountSelfCreationSettingsChanged: (state: IConfigurationState, action: PayloadAction<AccountSelfCreation>) => {
            state.accountSelfCreation = action.payload;
        }

    },
    extraReducers: (builder) => {

        builder.addCase(getConfigurationBasic.pending, (state: IConfigurationState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getConfigurationBasic.rejected, (state: IConfigurationState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getConfigurationBasic.fulfilled, (state: IConfigurationState, action: PayloadAction<BasicConfiguration>) => {
            state.status = ActionStatus.succeeded;
          
            state.authentication = action.payload.authentication;

            state.accountSelfCreation = action.payload.accountSelfCreation;

            state.branding = action.payload.branding;
        });

        builder.addCase(getConfiguration.pending, (state: IConfigurationState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getConfiguration.rejected, (state: IConfigurationState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getConfiguration.fulfilled, (state: IConfigurationState, action: PayloadAction<Configuration>) => {
            state.status = ActionStatus.succeeded;

            state.authentication = action.payload.authentication;

            state.accountSelfCreation = action.payload.accountSelfCreation;
            
            state.pages = action.payload.pages;

            state.branding = action.payload.branding;

        });

    }


});

export const {
    authenticationSettingsChanged,
    accountSelfCreationSettingsChanged,
    resetStatus
} = configurationSlice.actions;

export default configurationSlice.reducer;
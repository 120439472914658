import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { useActionChecker, usePageStrings } from '../../hooks';
import useWindowSize from '../../hooks/useWindowSize';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { revertIdentity } from "../../../store/slices/authorization";
import { mainContentScrollPositionChanged, navigationIconActivated } from '../../../store/slices/layout/layout.slice';
import { WindowSizeType } from "../../../store/slices/layout/layout.types";

import { AssumedIdentityBanner } from "./assumedIdentityBanner/AssumedIdentityBanner";
import { LinearProgress } from "../../components";
import { ModuleError } from "../../../app/errors/moduleError/ModuleError";
import { NavigationBar } from './navigationBar/NavigationBar';
import { NavigationDrawer } from './navigationDrawer/NavigationDrawer';
import { NavigationRail } from './navigationRail/NavigationRail';
import { TopAppBar } from './appBar/TopAppBar';
import SnackbarManager from "./snackbarManager/SnackbarManager";

import styles from './AppLayout.module.scss';

export default function AppLayout() {

    const dispatch = useAppDispatch();
    
    const authorization = useAppSelector((state: RootState) => state.authorization);
    const layout = useAppSelector((state) => state.layout);
    
    const actionChecker = useActionChecker();
    const location = useLocation();
    const pageStrings = usePageStrings();
    const navigate = useNavigate();

    const windowSizeType = useWindowSize();
        
    function handleScroll (e: any) {
        dispatch(mainContentScrollPositionChanged(e.target.scrollTop));
    }

    function handleNavigationMenuButtonClick (){
        dispatch(navigationIconActivated());
    }
   
    function handleNotificationButtonClick (){
        console.log('handleNotificationButtonClick');
    }

    function handleSignOutClick (){    
        navigate('/authentication/signout'); 
    }

    function handleRevertIdentityClicked() {

        dispatch(revertIdentity());

    }      

    if (!authorization.isAuthenticated){
        return <Navigate to="/authentication/signin"></Navigate>
    }

    if (layout.canAccessPolicy && !actionChecker.canDoAction(layout.canAccessPolicy)){
        return (<ModuleError message={pageStrings.general.error_NotAuthorized}></ModuleError>);
    }
    
    return (
        <div className={styles['AppLayout']}>

            { (windowSizeType === WindowSizeType.Large || windowSizeType === WindowSizeType.ExtraLarge) &&
                <div className={`${styles['AppLayout-navigationDrawer']} ${layout.appLayout.isNavigationDrawerOpened? styles['is-opened']: ''}`}>
                    <NavigationDrawer></NavigationDrawer>
                </div>
            }
 
            { (windowSizeType === WindowSizeType.Medium || windowSizeType === WindowSizeType.Expanded) &&
                <div className={`${styles['AppLayout-navigationRail']}`}>
                    <NavigationRail scollbarVisible={layout.windowSize.height <= 544}></NavigationRail>
                </div>
            }
 
            <div className={styles['AppLayout-content']}>
 
                <div  className={styles['AppLayout-contentScrollArea']} onScroll={handleScroll}>
 
                    <div className={styles['AppLayout-topAppBar']}>
                        <TopAppBar headline={layout.pageTitle} isScrolling={layout.mainContentScrollPosition>0}
                            showLeadingIcon={(windowSizeType === WindowSizeType.Large || windowSizeType === WindowSizeType.ExtraLarge)}
                            isNavigationMenuOpened={layout.appLayout.isNavigationDrawerOpened}
                            isNotificationVisible={false}
                            onNavigationMenuButtonClick={handleNavigationMenuButtonClick}
                            onNotificationButtonClick={handleNotificationButtonClick}
                            onSignoutClick={handleSignOutClick}></TopAppBar>

                        {layout.appLayout.isProgressVisible && 
                            <LinearProgress ariaLabel={pageStrings.layout.progress_AriaLabel}></LinearProgress>
                        }

                        {authorization.isAssumingIdentity &&
                            <AssumedIdentityBanner userFullName={authorization.userDisplayName} userIdentifier={authorization.userIdentifier} revertIdentityClicked={handleRevertIdentityClicked}></AssumedIdentityBanner>
                        }
                    </div>
               
                    <main className={`${styles['AppLayout-main']} container`}>
                        <SwitchTransition>
                            <CSSTransition key={location.pathname} timeout={1000} classNames={'page'} unmountOnExit>
                                <Outlet />
                            </CSSTransition>
                        </SwitchTransition>
                    </main>  
 
                </div>

                { windowSizeType === WindowSizeType.Compact &&
                    <div  className={`${styles['AppLayout-navigationBar']}`}>
                        <NavigationBar></NavigationBar>
                    </div>    
                }
                <SnackbarManager/>
            </div>  
 
        </div>
    );
}
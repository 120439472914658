import { useEffect, useState } from 'react';
import {
  FilledButton,
  OutlinedTextField,
  Form,
  Text,
  SelectOption,
  OutlinedSelect,
  BannerMessage,
  Divider,
  Link,
} from '../../../common/components';
import {
  Action,
  ActionStatus,
  useAppDispatch,
  useAppSelector,
} from '../../../store';
import { getDepartments } from '../../../store/slices/department';
import { getUserProfiles } from '../../../store/slices/userprofile';
import { getJobTitles } from '../../../store/slices/jobTitle/jobTitle.slice';
import { getPracticeAreas } from '../../../store/slices/practiceArea';
import {
  useLayout,
  usePageStrings,
} from '../../../common/hooks';
import { getLocations } from '../../../store/slices/location';
import { VisibilityOption } from '../../../viewModels/configuration/visibilityOption';
import { authenticationService } from '../../../store/slices/authentication';
import { useNavigate } from 'react-router-dom';
import {
  addSnackbarMessage,
  SnackbarMessage,
} from '../../../store/slices/snackbar';
import {
  PasswordRule,
  passwordRequirements,
} from '../../../common/features/password/PasswordRule';
import { useTranslation } from 'react-i18next';

import styles from './createAccount.module.scss';

export function CreateAccount() {
  const pageStrings = usePageStrings();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [createAccountAction, setCreateAccountAction] = useState(Action.idle());
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordPatternError, setPasswordPatternError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [formReady, setFormReady] = useState(false);

  const {
    department,
    location,
    practiceArea,
    jobTitle,
    userProfile,
    configurationAccountSelfCreation,
  } = useAppSelector((state) => ({
    department: state.department,
    location: state.location,
    practiceArea: state.practiceArea,
    jobTitle: state.jobTitle,
    userProfile: state.userProfile,
    configurationAccountSelfCreation: state.configuration.accountSelfCreation,
  }));

  // Configure layout
  useLayout({
    authorizationLayoutConfiguration: 'doubleColumn',
  });

  // Fetch necessary data on component load
  useEffect(() => {
    if (location.status === ActionStatus.idle) dispatch(getLocations());
    if (department.status === ActionStatus.idle) dispatch(getDepartments());
    if (userProfile.status === ActionStatus.idle) dispatch(getUserProfiles());
    if (jobTitle.status === ActionStatus.idle) dispatch(getJobTitles());
    if (practiceArea.status === ActionStatus.idle) dispatch(getPracticeAreas());
    if (location.status === ActionStatus.succeeded &&
      department.status === ActionStatus.succeeded &&
      userProfile.status === ActionStatus.succeeded &&
      jobTitle.status === ActionStatus.succeeded &&
      practiceArea.status === ActionStatus.succeeded) {
      setFormReady(true);
    }
  }, [department, jobTitle, userProfile, practiceArea, location, dispatch]);

  // Create SelectOptions from data
  const createSelectData = (data: any, nameKey: string, valueKey: string) =>
    data.map((item: { [key: string]: any }, index: number) => (
      <SelectOption
        key={item[valueKey] || `fallback-${index}`}
        optionName={item[nameKey]}
        optionValue={item[valueKey]}
      />
    ));

  const departmentSelectData = createSelectData(department.data, 'name', 'rowGUID');
  const practiceAreaSelectData = createSelectData(practiceArea.data, 'name', 'rowGUID');
  const userProfileSelectData = createSelectData(userProfile.data, 'name', 'rowGUID');
  const jobTitleSelectData = createSelectData(jobTitle.data, 'name', 'rowGUID');
  const locationSelectData = createSelectData(location.data, 'locationName', 'rowGUID');

  // Visibility helpers
  const isVisible = (config: any) => config !== VisibilityOption.Hidden;
  const isRequired = (config: any) => config === VisibilityOption.Required;

  // Form submission handler
  const handleSaveAuthenticationActivated = (formData: any) => {
    if (!passwordRequirements.every((req) => new RegExp(req.validation).test(password))) {
      setPasswordPatternError(true);
      return;
    }
    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }

    authenticationService
      .createAccount({
        department: formData['createaccount_department'],
        emailAddress: formData['createaccount_emailAddress'],
        firstname: formData['createaccount_firstName'],
        lastname: formData['createaccount_lastName'],
        location: formData['createaccount_location'],
        password: formData['createaccount_password'],
        phoneNumber: formData['createaccount_telephone'],
        practiceArea: formData['createaccount_practiceArea'],
        profile: formData['createaccount_profile'],
        title: formData['createaccount_title'],
        username: formData['createaccount_username'],
      })
      .then((result) => {
        if (result.isSuccess) {
          dispatch(addSnackbarMessage(new SnackbarMessage(result.value, 5)));
          setCreateAccountAction(Action.succeeded());
          navigate('/authentication/signin');
        } else {
          setCreateAccountAction(Action.failed(result.errorMessage));
        }
      });
  };

  // Password change handlers
  const handlePasswordChange = (e: { target: { value: string } }) => {
    setPassword(e.target.value);
    if (passwordPatternError) setPasswordPatternError(false);
  };

  function checkPasswords(e: React.ChangeEvent<HTMLInputElement>) {
    const inputElement = e.target;
    const confirmPassword = inputElement.value;

    // Check if passwords match
    if (!confirmPassword) {
      // If confirmPassword is empty, show "Required" error
      inputElement.setCustomValidity('Required');
      setPasswordMatchError(true);
    } else if (confirmPassword === password) {
      // Passwords match, clear error
      inputElement.setCustomValidity('');
      setPasswordMatchError(false);
    } else {
      // Passwords do not match, show mismatch error
      inputElement.setCustomValidity('Passwords do not match');
      setPasswordMatchError(true);
    }

    // Trigger browser validation
    inputElement.reportValidity();

    // Update value state (optional, if needed elsewhere)
    setConfirmPassword(confirmPassword);
  }

  return (
    <div className={styles.CreateAccount}>
      <header>
        <h1 className={styles['CreateAccount-heading']}>
          <Text
            fontStyle="headline-small"
            value={pageStrings.createNewUserPage.heading_createAccount_label}
          />
        </h1>
      </header>

      {createAccountAction.status === ActionStatus.failed && createAccountAction.message && (
        <div className={styles['CreateAccount-errorMessage']}>
          <BannerMessage messageType="error" message={createAccountAction.message} />
        </div>
      )}

      <Form onSubmit={handleSaveAuthenticationActivated}>
        <fieldset disabled={!formReady}>
          <div className={styles['CreateAccount-formFields']}>
            <OutlinedTextField
              id="createAccount-firstName"
              name="createaccount_firstName"
              required
              type='text'
              label={pageStrings.createNewUserPage.text_createAccount_firstName_label}
              placeholder={pageStrings.createNewUserPage.text_createAccount_firstName_label}
            />

            <OutlinedTextField
              name="createaccount_lastName"
              required
              type='text'
              label={pageStrings.createNewUserPage.text_createAccount_lastName_label}
              placeholder={pageStrings.createNewUserPage.text_createAccount_lastName_label}
            />

            <OutlinedTextField
              name="createaccount_emailAddress"
              required
              label={pageStrings.createNewUserPage.text_createAccount_emailAddress_label}
              type="email"
              placeholder={pageStrings.createNewUserPage.text_createAccount_emailAddress_label}
            />

            {isVisible(configurationAccountSelfCreation.formConfiguration_Username) && (
              <OutlinedTextField
                name="createaccount_username"
                label={pageStrings.createNewUserPage.text_createAccount_userName_label}
                placeholder={pageStrings.createNewUserPage.text_createAccount_userName_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_Username)}
              />
            )}

            <Divider />

            <OutlinedTextField
              name="createaccount_password"
              required
              label={pageStrings.createNewUserPage.text_createAccount_password_label}
              type="password"
              placeholder={pageStrings.createNewUserPage.text_createAccount_password_label}
              value={password}
              isError={passwordPatternError}
              errorText={passwordPatternError ? t(pageStrings.resetPasswordPage.error_passwordIncorrectFormat) : ''}
              onChange={handlePasswordChange}
            />

            <ul>
              {passwordRequirements.map((requirement, index) => (
                <li key={index}>
                  <PasswordRule
                    password={password}
                    label={requirement.label}
                    validation={requirement.validation}
                  />
                </li>
              ))}
            </ul>

            <OutlinedTextField
              name="createaccount_confirmPassword"
              required
              type="password"
              label={pageStrings.createNewUserPage.text_createAccount_confirmPassword_label}
              placeholder={pageStrings.createNewUserPage.text_createAccount_confirmPassword_label}
              isError={passwordMatchError}
              value={confirmPassword}
              errorText="Passwords do not match"
              //onChange={handleConfirmPasswordChange}
              onChange={checkPasswords}
            />

            <Divider />

            {isVisible(configurationAccountSelfCreation.formConfiguration_Telephone) && (
              <OutlinedTextField
                name="createaccount_telephone"
                label={pageStrings.createNewUserPage.text_createAccount_telephone_label}
                type="tel"
                placeholder={pageStrings.createNewUserPage.text_createAccount_telephone_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_Telephone)}
              />
            )}

            {isVisible(configurationAccountSelfCreation.formConfiguration_Location) && (
              <OutlinedSelect
                name="createaccount_location"
                label={pageStrings.createNewUserPage.text_createAccount_location_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_Location)}
                disabled={location.status !== ActionStatus.succeeded}
                includeBlankOption={true}
              >
                {locationSelectData}
              </OutlinedSelect>
            )}

            {isVisible(configurationAccountSelfCreation.formConfiguration_Title) && (
              <OutlinedSelect
                name="createaccount_title"
                label={pageStrings.createNewUserPage.text_createAccount_title_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_Title)}
                disabled={location.status !== ActionStatus.succeeded}
                includeBlankOption={true}
              >
                {jobTitleSelectData}
              </OutlinedSelect>
            )}

            {isVisible(configurationAccountSelfCreation.formConfiguration_Department) && (
              <OutlinedSelect
                name="createaccount_department"
                label={pageStrings.createNewUserPage.text_createAccount_department_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_Department)}
                disabled={location.status !== ActionStatus.succeeded}
                includeBlankOption={true}
              >
                {departmentSelectData}
              </OutlinedSelect>
            )}

            {isVisible(configurationAccountSelfCreation.formConfiguration_PracticeArea) && (
              <OutlinedSelect
                name="createaccount_practiceArea"
                label={pageStrings.createNewUserPage.text_createAccount_practiceArea_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_PracticeArea)}
                disabled={location.status !== ActionStatus.succeeded}
                includeBlankOption={true}
              >
                {practiceAreaSelectData}
              </OutlinedSelect>
            )}

            {isVisible(configurationAccountSelfCreation.formConfiguration_UserProfile) && (
              <OutlinedSelect
                name="createaccount_profile"
                label={pageStrings.createNewUserPage.text_createAccount_profile_label}
                required={isRequired(configurationAccountSelfCreation.formConfiguration_UserProfile)}
                disabled={location.status !== ActionStatus.succeeded}
                includeBlankOption={true}
              >
                {userProfileSelectData}
              </OutlinedSelect>
            )}
          </div>

          <div className={styles['CreateAccount-contentButton']}>
            <FilledButton type="submit" value={pageStrings.createNewUserPage.button_create_label} />
          </div>

          <div className={styles['CreateAccount-backToSignIn']}>
            <Link to="/authentication/signin" text={pageStrings.forgotCredentials.button_signIn_label} />
          </div>
        </fieldset>
      </Form>
    </div>
  );
}
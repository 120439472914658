import { useEffect, useRef } from "react";
import { ActionStatus, AppDispatch, useAppDispatch, useAppSelector } from "../../../../store";
import { localStorageService } from "../../../services/localStorage.service";
import { getPolicies, lms3clearLms4Cookie, lms3SetLms4Cookie, sessionExpired, setAuthorization } from "../../../../store/slices/authorization";
import i18n from "../../../../config/i18n";

import { configurationLoaded, policiesLoaded, setErrorMessage, translationFileLoaded } from "../../../../store/slices/layout";
import { accountSelfCreationSettingsChanged, authenticationSettingsChanged, configurationHub, getConfiguration, getConfigurationBasic } from "../../../../store/slices/configuration";
import themeService from "../../../services/theme.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import urlService from "../../../services/url.service";
import { usePageStrings } from "../../../hooks";

export function MainLayoutState (){

    const renderCount = useRef(0);
    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const authorization = useAppSelector((state) => state.authorization);
    const configuration = useAppSelector((state) => state.configuration);
    const layout = useAppSelector((state) => state.layout);
    
    const refUrl = getRefUrl(searchParams);

    const pageStrings = usePageStrings();
    
    useEffect(()=>{
        renderCount.current++; 
        i18n.on('loaded', function(loaded) {
            if(i18n.t('Labels.lbl_Save') !== 'Labels.lbl_Save'){
                dispatch(translationFileLoaded());
            }
        })
    },[dispatch]);

    useEffect(()=>{

        if (authorization.assumeIdentityStatus === ActionStatus.succeeded){

            if (authorization.assumeIdentityPropagationStatus === ActionStatus.idle){
                dispatch(lms3SetLms4Cookie());
            } 

        }
        
        if(authorization.revertIdentityStatus === ActionStatus.succeeded){

            if (authorization.revertIdentityPropagationStatus === ActionStatus.idle){

                dispatch(lms3SetLms4Cookie());              

            }
            
        }

    },[
        authorization.assumeIdentityStatus, 
        authorization.assumeIdentityPropagationStatus, 
        authorization.revertIdentityStatus, 
        authorization.revertIdentityPropagationStatus,
        dispatch
    ]);

    
    useEffect(()=>{
        renderCount.current++; 
        console.log(`MainLayoutState render count: ${renderCount.current}`);

        const isSessionExpired = (localStorageService.getAction() === 'session_expired');
        const authorizationTokenInLocalStorage = localStorageService.getAuthorizationToken();
        const userPermissionsInLocalStorage = localStorageService.getUserPermissions();

        const isBasicConfig = new URL(window.location.href).pathname.startsWith('/authentication/')

        // check if session expired
        if (isSessionExpired){
            dispatch(sessionExpired());
            navigate('/authentication/signin');
        }

        // recover session if required
        if (!authorization.token && authorizationTokenInLocalStorage){
            
            dispatch(setAuthorization({
                token: authorizationTokenInLocalStorage,
                userPermissions: userPermissionsInLocalStorage
            }));

        } else {

            if (authorization.token){

                if (authorization.signInStatus === ActionStatus.succeeded){
                
                    if (authorization.signInPropagationStatus === ActionStatus.succeeded){
                        window.location.assign(refUrl);
                    } else if (authorization.signInPropagationStatus === ActionStatus.idle){
                        dispatch(lms3SetLms4Cookie());   
                    }
                        
                }
    
            } else {

                // check if needs to redirect to sign in
                if (!isBasicConfig){
                    navigate('/authentication/signin');
                }
            }

            // load session 
            if (layout.status === ActionStatus.idle){

                // load translation files
                i18n.init((err: any, t) => {
                    if (err) {
                        dispatch(setErrorMessage('Translation file not found'));
                    }
                });
            }

            if (authorization.signOutStatus === ActionStatus.succeeded){

                if (authorization.signOutPropagationStatus === ActionStatus.idle){
                    dispatch(lms3clearLms4Cookie());
                } else if (authorization.signOutPropagationStatus === ActionStatus.succeeded){
                    navigate('/authentication/signout');
                }

            }

            // load configuration
            if (configuration.status === ActionStatus.idle){

                dispatch(isBasicConfig ? 
                    getConfigurationBasic():
                    getConfiguration());

            } else if (configuration.status === ActionStatus.failed){

                dispatch(setErrorMessage('configuration not found'));

            } else if (configuration.status === ActionStatus.succeeded){

                dispatch(configurationLoaded());

                configurationHub.start(()=>{
                    loadHubListeners(dispatch);
                });
                            
            }
            
        }

        // load theming
        const themeStyle = document.createElement('style');

        themeStyle.innerHTML = themeService.generateCssVariables({
             primaryKeyColor: configuration.branding.keyColor_primary,
             secondaryKeyColor: configuration.branding.keyColor_secondary
        });

        document.head.appendChild(themeStyle);

        return () => {
            document.head.removeChild(themeStyle);
        }
        
    }, [authorization, layout.status, dispatch, configuration, refUrl, navigate]);

    

    useEffect(()=> {
        
        if (authorization.status === ActionStatus.idle) {
            
            dispatch(getPolicies());

        } else if (authorization.status === ActionStatus.failed){
            dispatch(setErrorMessage(pageStrings.general.error_Unexpected));
        } else if (authorization.status === ActionStatus.succeeded){
            dispatch(policiesLoaded());
        }  

    }, [authorization, configuration, dispatch, pageStrings]);
    
    return (
        <>
        </>
    );
}


function loadHubListeners(dispatch: AppDispatch)
{
    console.log('loadHubListeners');
    configurationHub.OnAuthenticationSettingsChanged((authenticationSettings: any) =>{
        dispatch(authenticationSettingsChanged(authenticationSettings));
    });

    configurationHub.OnAccountSelfCreationSettingsChanged((accountSelfCreationSettings: any) =>{
        dispatch(accountSelfCreationSettingsChanged(accountSelfCreationSettings));
    });

}

function getRefUrl (searchParams: URLSearchParams){
    
    let refUrl = searchParams.get("refUrl") ?? urlService.buildLegacyAppUrl("");

    if(!refUrl.includes('tzName=')){

        const joinCharacter = refUrl.includes('?')? '&': '?';

        refUrl = `${refUrl}${joinCharacter}tzName=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`;

    }

    return refUrl;
}
import { useEffect } from "react";
import { ActionStatus, useAppDispatch, useAppSelector } from "../../../store";
import { revertIdentity} from "../../../store/slices/authorization";

import styles from './RevertIdentity.module.scss';
import { LinearProgress, Text } from "../../../common/components";
import { useNavigate } from "react-router-dom";
import { resetStatus } from "../../../store/slices/configuration";


export function RevertIdentity () {

    const dispatch = useAppDispatch();
    const authorization = useAppSelector((state) => state.authorization);
    const navigate = useNavigate();

    useEffect(() => {

        if(authorization.revertIdentityStatus === ActionStatus.idle)
        {
            dispatch(revertIdentity());

        } else if (authorization.revertIdentityStatus === ActionStatus.succeeded){

            if (authorization.revertIdentityPropagationStatus === ActionStatus.succeeded){
                dispatch(resetStatus())
                navigate('/profile/me');
            }

        }

    }, [authorization, dispatch, navigate]);

    return (
        <section className={styles['RevertIdentity']}>
            <p>
                <Text fontStyle="body-large" value="Reverting Identity"></Text>
            </p>
            <LinearProgress ariaLabel="Reverting Identity"></LinearProgress>
        </section>
    );
}
import { createComponent } from "@lit/react";
import { MdSelectOption } from '@material/web/select/select-option';
import React from "react";


const SelectOptionComponent = createComponent({
    tagName: 'md-select-option',
    elementClass: MdSelectOption,
    react: React
});

export function BlankSelectOption(){
  
    return (
        <SelectOptionComponent aria-label={'blank'}>
        </SelectOptionComponent>
    );

}
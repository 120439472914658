import React, { useState } from 'react';
import { createComponent } from '@lit/react';

import { MdOutlinedSelect } from '@material/web/select/outlined-select';

import styles from './OutlinedSelect.module.scss';
import { useTranslation } from 'react-i18next';
import { BlankSelectOption } from './BlankSelectOption';

const OutlinedSelectComponent = createComponent({
    tagName: 'md-outlined-select',
    elementClass: MdOutlinedSelect,
    react: React,
    events: {
       'onInput': 'input',
       'onChange': 'change',
    }
});

interface IComponentProperties {
    name?: string;
    label: string;
    required?: boolean | undefined;
    supportingText?: string | undefined;
    value?: string | undefined;
    children?: React.ReactNode[] | undefined;
    disabled?: boolean;
    includeBlankOption?: boolean;
}

/**
 * Max size 1500 items
 */
export function OutlinedSelect(props: IComponentProperties) {
    const { t } = useTranslation();

    const [value, setValue] = useState(props.value || '');

    function handleChange(args: any){
        setValue(args.target.value);
    }

    return (
        <div className={styles['OutlinedSelect-container']}>
            <OutlinedSelectComponent
                name={props.name}
                className={styles['OutlinedSelect']}
                label={t(props.label)}
                aria-label={t(props.label)}
                required={props.required === true}
                supportingText={t(props.supportingText ?? '')}
                value={value}
                onChange={handleChange}
                disabled={props.disabled} // Pass the disabled prop
            >
                {props.includeBlankOption === true && 
                    <BlankSelectOption></BlankSelectOption>
                    
                }
                {props.children}
            </OutlinedSelectComponent>
        </div>
    );
}
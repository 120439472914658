import { useState } from "react";
import { ActionStatus, useAppSelector } from "../../../store";
import { UserBasic } from "../../../viewModels/user/userBasic";
import { Divider, FilledTextField, LinearProgress, Text } from "../../components";
import { usePageStrings } from "../../hooks";
import { UserList } from "./UserList";

import styles from './UserSelector.module.scss';
import { useTranslation } from "react-i18next";

interface IComponentProperties {
    onListItemClicked: (userBasic: UserBasic) => void
}

export function UserSelector(props: IComponentProperties) {

    const pageStrings = usePageStrings();
    const {t} = useTranslation();
    const user = useAppSelector(state => state.user);

    const [users, setUsers] = useState<UserBasic[]>([]);
    const [searchFilter, setSearchFilter] = useState("");

    function handleItemClicked(user: UserBasic) {
        props.onListItemClicked(user);
    }

    function handleSearchTextTyped(e: React.ChangeEvent<HTMLInputElement>) {

        const value = e.target.value;

        let filteredUsers: UserBasic[] = [];
        setSearchFilter(value);

        if (value && value.length >= 3) {

            filteredUsers = user.users.filter(item => {
                return item.email.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                    item.fullName.toLowerCase().indexOf(value.toLowerCase()) >= 0;
            });

            filteredUsers = filteredUsers
                .sort((a, b) => a.fullName.localeCompare(b.fullName)) // Sort by fullName
                .slice(0, 25);
        }

        setUsers(filteredUsers);
    }

    return (
        <div className={styles['UserSelector']}>

            {user.status === ActionStatus.loading &&
                <LinearProgress ariaLabel={pageStrings.layout.progress_AriaLabel}></LinearProgress>
            }

            <div className={styles['UserSelector-searchField']}>
                <FilledTextField name="userFilter"
                    type='search'
                    label=''
                    placeholder={pageStrings.myProfilePage.button_search_placeholder}
                    supportingText={t(pageStrings.myProfilePage.button_supportingText_search)}
                    value={searchFilter}
                    onInput={handleSearchTextTyped}>
                </FilledTextField>
            </div>
            {users.length > 0 &&
                <>
                    <Divider></Divider>

                    <div className={styles['UserSelector-scroller']}>
                        <div className={styles['UserSelector-results']}>

                            {user.status === ActionStatus.succeeded &&
                                <UserList users={users} onItemClicked={handleItemClicked}></UserList>
                            }

                        </div>
                    </div>

                </>
            }
            {users.length === 0 && searchFilter.length >= 3 &&
                <p className={styles['UserSelector-empty']}><Text fontStyle="body-medium" value="No users found"></Text></p>
            }


        </div>
    );
}